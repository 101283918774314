import Vue from 'vue';
import store from '@/store';
import vueRouter from 'vue-router';
import App from '@/App.vue';
import {
	routerList
} from '@/api/Router';
Vue.use(vueRouter);

const routes = [{
		path: '/',
		redirect: '/HomePage',
	},
	{
		path: '/login',
		name: 'loginT',
		component: () => import('../pages/login/index.vue'),
	},
	{
		path: '/404',
		component: () => import('../pages/error/index.vue'),
		hidden: true
	},

]

let deleteRoute = [{
		path: '/AdminManage',
		name: 'AdminManage',
		component: () => import('../pages/home/AdminManage/index'),
	},
	{
		path: '/Pagedecoration',
		name: 'Pagedecoration',
		component: () => import('../pages/home/Pagedecoration/index.vue'),
	},
	{
		path: '/RoleManage',
		name: 'RoleManage',
		component: () => import('../pages/home/RoleManage/index'),
	},
	{
		path: '/MenuManage',
		name: 'MenuManage',
		component: () => import('../pages/home/MenuManage/index.vue'),
	},
	{
		path: '/LogManage',
		name: 'LogManage',
		component: () => import('../pages/home/LogManage/index.vue'),
	},
	{
		path: '/Goods',
		name: 'Goods',
		component: () => import('../pages/home/Goods/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Classify',
		name: 'Classify',
		component: () => import('../pages/home/Classify/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Spec',
		name: 'Spec',
		component: () => import('../pages/home/Spec/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Tags',
		name: 'Tags',
		component: () => import('../pages/home/Tags/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/TodayGoods',
		name: 'TodayGoods',
		component: () => import('../pages/home/TodayGoods/index.vue'),
	},
	{
		path: '/Parame',
		name: 'Parame',
		component: () => import('../components/Parame.vue'),
	},
	{
		path: '/Serve',
		name: 'Serve',
		component: () => import('../pages/home/Serve/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Brand',
		name: 'Brand',
		component: () => import('../pages/home/Brand/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Vehicle',
		name: 'Vehicle',
		component: () => import('../pages/home/Vehicle/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Order',
		name: 'Order',
		component: () => import('../pages/home/Order/index.vue'),
	},
	{
		path: '/OrderRefund',
		name: 'OrderRefund',
		component: () => import('../pages/home/OrderRefund/index.vue'),
	},
	{
		path: '/Slideshow',
		name: 'Slideshow',
		component: () => import('../pages/home/Slideshow/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/SpecialSubject',
		name: 'SpecialSubject',
		component: () => import('../pages/home/SpecialSubject/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/HelpAndFeedback',
		name: 'HelpAndFeedback',
		component: () => import('../pages/home/HelpAndFeedback/index.vue'),
	},
	{
		path: '/Index',
		name: 'Index',
		component: () => import('../pages/home/Index/index.vue'),
	},
	{
		path: '/Article',
		name: 'Article',
		component: () => import('../pages/home/Article/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Advertisement',
		name: 'Advertisement',
		component: () => import('../pages/home/Advertisement/index.vue'),
		meta: {
			requireAuth: true
		},
	},
	{
		path: '/Activities',
		name: 'Activities',
		component: () => import('../pages/home/Activities/index.vue'),
	},
	{
		path: '/Store',
		name: 'Store',
		component: () => import('../pages/home/Store/index.vue'),
		meta: {
			requireAuth: true
		},
	},
]




let routeAdd = [{
		path: '/pages',
		name: 'PagesT',
		component: () => import('../pages/index.vue'),
		meta: {
			requireAuth: true,
			title: '首页'
		},
		children: [{
				path: '/HomePage',
				name: 'HomePage',
				component: () => import('../pages/home/HomePage/index.vue'),
				meta: {
					requireAuth: true
				},
			},

			{
				path: '/StoreUser',
				name: 'StoreUser',
				component: () => import('../components/StoreUser.vue'),
			},
			{
				path: '/SupplierAdmin',
				name: 'SupplierAdmin',
				component: () => import('../components/SupplierAdmin.vue'),
			},
			{
				path: '/User',
				name: 'User',
				component: () => import('../pages/home/User/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			{
				path: '/GoodsAdd',
				name: 'GoodsAdd',
				component: () => import('../components/GoodsAdd.vue'),
				meta: {
					requireAuth: true
				},
			},

			{
				path: '/Version',
				name: 'Version',
				component: () => import('../pages/home/Version/index.vue'),
				meta: {
					requireAuth: true
				},
			},

			{
				path: '/warehouse',
				name: 'warehouse',
				component: () => import('../pages/home/Warehouse/index.vue'),
			},
			{
				path: '/WarehouseStock',
				name: 'WarehouseStock',
				component: () => import('../components/WarehouseStock.vue'),
			},

			{
				path: '/WarehouseAdmin',
				name: 'WarehouseAdmin',
				component: () => import('../components/WarehouseAdmin.vue'),
			},

			{
				path: '/PaySetting',
				name: 'PaySetting',
				component: () => import('../pages/home/PaySetting/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			{
				path: '/ContentSetting',
				name: 'ContentSetting',
				component: () => import('../pages/home/ContentSetting/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			{
				path: '/ceshi',
				name: 'ceshi',
				component: () => import('../pages/home/ceshi/index.vue'),
			},
			{
				path: '/searchMage',
				name: 'searchMage',
				component: () => import('../pages/home/searchMage/searchMage.vue'),
			},
			{
				path: '/replenishManage',
				name: 'replenishManage',
				component: () => import('../pages/home/replenishManage/replenishManage.vue'),
			},
			{
				path: '/MenuManage',
				name: 'MenuManage',
				component: loadView('pages/home/MenuManage/index'),
				// component: loadView('home/MenuManage/index')
				// component: () => import('/src/pages/home/MenuManage/index.vue'),
			},
			{
				path: '/CouponManage',
				name: 'CouponManage',
				component: () => import('../pages/home/CouponManage/index.vue'),
			},
			{
				path: '/AddCoupon',
				name: 'AddCoupon',
				component: () => import('../pages/home/CouponManage/AddCoupon.vue'),
			},
			{
				path: '/addressPage',
				name: 'addressPage',
				component: () => import('../pages/home/addressPage/index.vue'),
			},
			{
				path: '/addAddress',
				name: 'addAddress',
				component: () => import('../pages/home/addressPage/addAddress.vue'),
			},
			{
				path: '/BargainManage',
				name: 'BargainGoods',
				component: () => import('../pages/home/BargainManage/BargainGoods.vue'),
			},
			{
				path: '/AddBargainGoods',
				name: 'AddBargainGoods',
				component: () => import('../pages/home/BargainManage/AddBargainGoods.vue'),
			},
			{
				path: '/AddBargainGoods',
				name: 'UserBargain',
				component: () => import('../pages/home/BargainManage/UserBargain.vue'),
			},
		],
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true
	}
]






const router = new vueRouter({
	routes,
});
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token');
	if (to.meta.requireAuth && !token) {
		next({
			name: 'loginT',
		});
	} else {
		next();
	}
});

//
function loadView(view) {
	const index = view.indexOf('/');
	if (view.slice(0, index) == 'pages') {
		return () => import(`@/pages/${view.slice(index + 1)}`);
	} else {
		return () => import(`@/components/${view.slice(index + 1)}`);
	}
}

//获取权限码
store.dispatch("user/permissionFun");


// 获取动态路由
routerList().then(res => {
	// let homeChildren = routeAdd[0].children
	let list = []
	res.data.forEach((item) => {
		list.push({
			path: `/${item.name}`,
			name: item.name,
			component: loadView(item.url),
			meta: {
				requireAuth: true
			},
		})
	})
	routeAdd[0].children = routeAdd[0].children.concat(list)
	// console.log('接口请求的的',res.data)
	// console.log('添加完后的',list)
	router.addRoutes(routeAdd)
	// console.log('路由列表',router.getRoutes())
})



// setTimeout(() => {
//   router.addRoutes(routeAdd)
// 更新路由匹配器
//   router.matcher = new vueRouter({
//     routes: routes
//   }).matcher;

// },1000)




export default router;