import axios from 'axios';
import {
	Message
} from 'element-ui';
import router from '@/router';
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
let baseWebURL = '';
// 环境的切换
// if (process.env.NODE_ENV == 'development') { //开发环境
baseWebURL = process.env.VUE_APP_BASE_API; //多加一个api是后台那边统一拦截处理视项目情况而定加不加）
// baseWebURL = 'http://192.168.1.15:85/'; //ss多加一个api是后台那边统一拦截处理视项目情况而定加不加）
// } else if (process.env.NODE_ENV == 'test') { //测试环境
//   baseWebURL = 'http://39.98.112.95/';
// } else if (process.env.NODE_ENV == 'production') { //生产环境
//   baseWebURL = 'http://39.98.112.95/';
// }
//生成一个axios实例
const service = axios.create({
	baseURL: baseWebURL,
	//1.请求超时时间
	timeout: 15000
});
// console.log(service, 'service')
//3.公共部分(请求带token设置)
// service.defaults.headers.common['token'] = '1111111';
//4.返回数据类型的定义
// service.defaults.responseType = 'json';
//5.带cookie请求
// service.defaults.withCredentials = false

// 请求拦截器
service.interceptors.request.use(
	config => {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
		config.headers['source-application'] = 'com.rd.web';
		const token = localStorage.getItem('token')
		config.headers['token'] = token
		return config;
	}, error => {
		return Promise.reject(error);
	});

// 响应拦截器
service.interceptors.response.use(
	response => {
		if (response.status === 200) {
			if (response.data.code === 20001) {
				Message({
					showClose: true,
					msg: response.data.msg || '未知错误',
					type: 'error'
				});
				router.replace({
					path: '/login',
					query: {
						redirect: router.currentRoute.fullPath
					}
				});
				return Promise.resolve({
					success: false,
					data: null,
					msg: response.data.msg || '未知错误'
				});
			} else if (response.data.code === 200) {
				return Promise.resolve({
					code: response.data.code,
					data: response.data.data,
					msg: response.data.msg || '未知错误'
				});
			} else if (response.data.code === 201) {
				return Promise.resolve({
					code: response.data.code,
					data: response.data.data,
					msg: response.data.msg || '未知错误'
				});
				// return
				// Message({
				// 	showClose: true,
				// 	msg: response.data.msg || '未知错误',
				// 	type: 'error'
				// });
				// return Promise.resolve({
				// 	success: false,
				// 	data: null,
				// 	msg: response.data.msg || '未知错误'
				// });
			} else {
				console.log('response-->,else')
				// Message({
				// 	showClose: true,
				// 	msg: response.data.msg || '未知错误',
				// 	type: 'error'
				// });
				return Promise.resolve({
					code: response.data.code,
					data: response.data.data,
					msg: response.data.msg || '未知错误'
				});
			}
		} else {
			return Promise.resolve({
				success: false,
				data: null,
				msg: '请求失败，状态码不是200'
			});
		}
	},
	error => {
		if (error.code === 'ECONNABORTED') {
			Message({
				showClose: true,
				msg: '请求超时，请刷新页面',
				type: 'error'
			});
		} else {
			Message({
				showClose: true,
				msg: error.message || '网络错误',
				type: 'error'
			});
		}
		return Promise.resolve({
			success: false,
			data: null,
			msg: error.message || '网络错误'
		});
	}
);


// 封装axios的get请求
export function getData(url, params) {
	return service({
		url: url,
		method: 'GET',
		params
	});

	// return new Promise((resolve, reject) => {
	//   service.get(url, {params:params}).then(response => {
	//     resolve(response.data);
	//   })
	//     .catch(error => {
	//       reject(error);
	//     });
	// });
}
// 封装axios的post请求
export function postData(url, data) {
	return service({
		url: url,
		method: 'POST',
		data
	});
	// return new Promise((resolve, reject) => {
	//   service.post(url, params).then(response => {
	//     console.log(111,'掉了两次')
	//
	//
	//     resolve(response.data);
	//   })
	//     .catch(error => {
	//       reject(error);
	//     });
	// });
}